import React from 'react'
import '../styles/contact.scss'
import { Contact as ContactComp } from '../components'

const Contact = () => {
  return (
    <div className='contact-page'>
      <ContactComp theme={'light'} activeSection={[]} />
    </div>
  )
}

export default Contact