import { FiMonitor } from "react-icons/fi";
import { RiCodeView } from "react-icons/ri";
import { TbDeviceMobileCode,TbSpeakerphone } from "react-icons/tb";
import { HiOutlineShoppingCart } from "react-icons/hi";
import { IoFastFoodOutline,IoChatbubblesOutline,IoCodeSlash,IoTimerOutline,IoEyeOutline } from "react-icons/io5";
import { BsStars } from "react-icons/bs";
import { MdOutlineDesignServices } from "react-icons/md";
// import { SiSpeedtest } from "react-icons/si";
import { VscDebug,VscChecklist } from "react-icons/vsc";
import { FaRocket } from "react-icons/fa6";
import { FaUsers,FaBtc } from "react-icons/fa";

// import { GiDuration } from "react-icons/gi";
import { LuMonitorSmartphone } from "react-icons/lu";





const Services = [
    {
        name:'تطوير المواقع',
        desc:'ننشؤا في جميع أنواع المواقع من المواقع التعريفية الى المنصات التعليمية و بأحدث التقنيات',
        imageSrc:'/images/services/web-dev.png',
        icon:FiMonitor,
        stepsHeading:'مراحل تطوير المواقع في ڤيلوسيتي',
        steps:[
            {
                stepName:'مرحلة الاستكشاف',
                stepDesc:'تتضمن هذه المرحلة فهم المشكلة المراد حلها و إنشاء الانظمة المبدئية و عرض الاقتراحات المناسبة و ذلك من خلال الاجتماعات الدورية',
                stepImage:'/images/steps/meeting.png',
                stepIcon:BsStars
            },
            {
                stepName:'مرحلة التصميم',
                stepDesc:'تتم في هذه المرحلة تصميم جميع الواجهات المتفق لإنشاء النظام بالاضافة لمشاركتها و القيام بالتعديلات ان وجد',
                stepImage:'/images/steps/design.png',
                stepIcon:MdOutlineDesignServices
            },
            {
                stepName:'مرحلة البرمجة',
                stepDesc:'بمجرد الانتهاء من مرحلة التصميم يتم بدء العمل في تحويل التصميم الى واقع وتتضمن هذه المرحلة مشاركة التحديثات بشكل دوري لضمان كفائت النظام النهائي مع العلم أنه لا يمكن اجراء تعديلات على التصميم في هذه المرحلة',
                stepImage:'/images/steps/developing.png',
                stepIcon:IoCodeSlash
            },
            {
                stepName:'مرحلة الاختبار',
                stepDesc:'وتعتبر هذه المرحلة النهائية و هدفها الأساسي ضمان عمل النظام بشكل متكامل و بدون أي أخطاء',
                stepImage:'/images/steps/testing.png',
                stepIcon:VscDebug
            },
            {
                stepName:'الإشراف و الدعم',
                stepDesc:'لا تنتهي مهمتنا بمجرد اطلاق الموقع بل تمدد الى الاشراف على النظام و التأكد من استعماله بالطريقة الصحيحة ويشمل ذلك إدارة المحتوى و التعديلات البرمجية',
                stepImage:'/images/steps/supporting.png',
                stepIcon:FaRocket
            },
        ],
        technologies: [
            {
                technoName:'للتصميم',
                technos:[
                    '/images/technologies/figma.png',
                    '/images/technologies/photoshop.png',
                ]
            },
            {
                technoName:'للبرمجة',
                technos:[
                    '/images/technologies/html.png',
                    '/images/technologies/sass.png',
                    '/images/technologies/js.png',
                    '/images/technologies/react.png',
                    '/images/technologies/node.png',
                    '/images/technologies/mongodb.png',
                    '/images/technologies/electron.png',
                ]
            },
            {
                technoName:'للنشر',
                technos:[
                    '/images/technologies/aws.png',
                    '/images/technologies/goDaddy.png',
                    '/images/technologies/google.png',
                    '/images/technologies/hostinger.png',
                ]
            },
        ]
    },
    {
        name:'تطوير التطبيقات',
        desc:'جميع التطبيقات اللي نخدموا فيها بمواصفات عالية و واجهات جذابة ',
        imageSrc:'/images/services/app-dev.png',
        icon:TbDeviceMobileCode,
        stepsHeading:'مراحل تطوير التطبيقات في شركة ڤيلوسيتي',
        steps:[
            {
                stepName:'مرحلة الاستكشاف',
                stepDesc:'تتضمن هذه المرحلة فهم نوع التطبيق المراد تطويره  و عرض الاقتراحات ومزايا التطبيق الممكن اضافتها و ذلك من خلال الاجتماعات الدورية',
                stepImage:'/images/steps/meeting.png',
                stepIcon:BsStars
            },
            {
                stepName:'مرحلة التصميم',
                stepDesc:'تتم في هذه المرحلة تصميم جميع الواجهات المتفق لإنشاء التطبيق بالاضافة لمشاركتها و القيام بالتعديلات ان وجد',
                stepImage:'/images/steps/design.png',
                stepIcon:MdOutlineDesignServices
            },
            {
                stepName:'مرحلة البرمجة',
                stepDesc:'بمجرد الانتهاء من مرحلة التصميم يتم بدء العمل في تحويل التصميم الى واقع وتتضمن هذه المرحلة مشاركة التحديثات بشكل دوري لضمان جودة التطبيق النهائي مع العلم أنه لا يمكن اجراء تعديلات على التصميم في هذه المرحلة',
                stepImage:'/images/steps/developing.png',
                stepIcon:IoCodeSlash
            },
            {
                stepName:'مرحلة الاختبار',
                stepDesc:'وتعتبر هذه المرحلة النهائية و هدفها الأساسي هو الاختبار لضمان عمل جميع أجزاء التطبيق بشكل متكامل و بدون أي أخطاء',
                stepImage:'/images/steps/testing.png',
                stepIcon:VscDebug
            },
            {
                stepName:'الإشراف و الدعم',
                stepDesc:'لا تنتهي مهمتنا بمجرد اطلاق التطبيق بل تمدد الى الاشراف على التطبيق و التأكد من استعماله بالطريقة الصحيحة ويشمل ذلك إدارة المحتوى و التعديلات البرمجية',
                stepImage:'/images/steps/supporting.png',
                stepIcon:FaRocket
            },
        ],
        technologies: [
            {
                technoName:'للتصميم',
                technos:[
                    '/images/technologies/figma.png',
                    '/images/technologies/photoshop.png',
                ]
            },
            {
                technoName:'للبرمجة',
                technos:[
                    '/images/technologies/css.png',
                    '/images/technologies/js.png',
                    '/images/technologies/react-native.png',
                    '/images/technologies/redux.png',
                    '/images/technologies/expo.png',
                    '/images/technologies/express.png',
                ]
            },
            {
                technoName:'للنشر',
                technos:[
                    '/images/technologies/app_store.png',
                    '/images/technologies/google-play.png',
                    '/images/technologies/aws.png',
                    '/images/technologies/google.png',
                ]
            },
        ]
    },
    {
        name:'تصميم الحلول البرمجية',
        desc:'مهما كانت مشكلتك معقدة فريقنا حيكون متمكن من ايجاد حل برمجي فعال',
        imageSrc:'/images/services/software-dev.png',
        icon:RiCodeView,
        stepsHeading:'مراحل إنشاء الحلول البرمجية في شركة ڤيلوسيتي',
        steps:[
            {
                stepName:'مرحلة الاستكشاف',
                stepDesc:'تتضمن هذه المرحلة فهم المشكلة المراد حلها و إنشاء الحلول المبدئية و عرض الاقتراحات المناسبة و ذلك من خلال الاجتماعات الدورية',
                stepImage:'/images/steps/meeting.png',
                stepIcon:BsStars
            },
            {
                stepName:'مرحلة التصميم',
                stepDesc:'تتم في هذه المرحلة تصميم جميع الواجهات اللازمة لإنشاء حل متكامل بالاضافة لمشاركتها و القيام بالتعديلات ان وجد',
                stepImage:'/images/steps/design.png',
                stepIcon:MdOutlineDesignServices
            },
            {
                stepName:'مرحلة البرمجة',
                stepDesc:'بمجرد الانتهاء من مرحلة التصميم يتم بدء العمل في تحويل التصميم الى واقع وتتضمن هذه المرحلة مشاركة التحديثات بشكل دوري لضمان كفائت النظام النهائي مع العلم أنه لا يمكن اجراء تعديلات على التصميم في هذه المرحلة',
                stepImage:'/images/steps/developing.png',
                stepIcon:IoCodeSlash
            },
            {
                stepName:'مرحلة الاختبار',
                stepDesc:'وتعتبر هذه المرحلة النهائية و هدفها الأساسي ضمان عمل الحل البرمجي بشكل متكامل و بدون أي أخطاء',
                stepImage:'/images/steps/testing.png',
                stepIcon:VscDebug
            },
            {
                stepName:'الإشراف و الدعم',
                stepDesc:'لا تنتهي مهمتنا بمجرد تسليم النظام بل تمدد الى الاشراف على النظام و التأكد من استعماله بالطريقة الصحيحة',
                stepImage:'/images/steps/supporting.png',
                stepIcon:FaRocket
            },
        ],
        technologies: [
            {
                technoName:'للتصميم',
                technos:[
                    '/images/technologies/figma.png',
                ]
            },
            {
                technoName:'للبرمجة',
                technos:[
                    '/images/technologies/js.png',
                    '/images/technologies/react.png',
                    '/images/technologies/electron.png',
                    '/images/technologies/node.png',
                    '/images/technologies/mongodb.png',
                ]
            },
            {
                technoName:'أخرى',
                technos:[
                    '/images/technologies/aws.png',
                    '/images/technologies/google.png',
                    '/images/technologies/goDaddy.png',
                ]
            },
        ]
    },
    {
        name:'إنشاء المتاجر الالكترونية',
        desc:'كل ما زاد حجم متجرك الالكتروني زاد احتياجك لموقع ليه بش يمكنك من عرض جميع المنتجات و تنسيق الطلبات',
        imageSrc:'/images/services/ecom-dev.png',
        icon:HiOutlineShoppingCart,
        stepsHeading:'المراحل لإنشاء متجر الكتروني في ڤيلوسيتي',
        steps:[
            {
                stepName:'مرحلة الاستكشاف',
                stepDesc:'تتضمن هذه المرحلة فهم نوع المنتجات المراد بيعها و توضيح المزايا اللازم اضافتها و ذلك من خلال الاجتماعات الدورية',
                stepImage:'/images/steps/meeting.png',
                stepIcon:BsStars
            },
            {
                stepName:'مرحلة التصميم',
                stepDesc:'تتم في هذه المرحلة تصميم جميع واجهات المتجر و القيام بالتعديلات ان وجد',
                stepImage:'/images/steps/design.png',
                stepIcon:MdOutlineDesignServices
            },
            {
                stepName:'مرحلة البرمجة',
                stepDesc:'بمجرد الانتهاء من مرحلة التصميم يتم بدء في عملية إنشاء المتجر وتتضمن هذه المرحلة مشاركة التحديثات بشكل دوري مع العلم أنه لا يمكن اجراء تعديلات على التصميم في هذه المرحلة',
                stepImage:'/images/steps/developing.png',
                stepIcon:IoCodeSlash
            },
            {
                stepName:'مرحلة الاختبار',
                stepDesc:'وتعتبر هذه المرحلة النهائية و هدفها الأساسي ضمان عمل المتجر بشكل متكامل و بدون أي أخطاء',
                stepImage:'/images/steps/testing.png',
                stepIcon:VscDebug
            },
            {
                stepName:'الإشراف و الدعم',
                stepDesc:'لا تنتهي مهمتنا بمجرد اطلاق المتجر بل تمدد الى الاشراف و التأكد من استعماله بالطريقة الصحيحة ويشمل ذلك إدارة المحتوى و التعديلات البرمجية',
                stepImage:'/images/steps/supporting.png',
                stepIcon:FaRocket
            },
        ],
        technologies: [
            {
                technoName:'للتصميم',
                technos:[
                    '/images/technologies/figma.png',
                    '/images/technologies/photoshop.png',
                ]
            },
            {
                technoName:'للبرمجة',
                technos:[
                    '/images/technologies/html.png',
                    '/images/technologies/sass.png',
                    '/images/technologies/js.png',
                    '/images/technologies/react.png',
                    '/images/technologies/node.png',
                    '/images/technologies/mongodb.png',
                    '/images/technologies/electron.png',
                ]
            },
            {
                technoName:'للنشر',
                technos:[
                    '/images/technologies/aws.png',
                    '/images/technologies/goDaddy.png',
                    '/images/technologies/google.png',
                    '/images/technologies/hostinger.png',
                ]
            },
        ]
    },
    {
        name:'تصميم قوائم الطعام الإلكترونية ',
        desc:'سهل على زباينك ووفرلهم قائمة طعام الكترونية بالQR مع امكانية تعديل الاصناف و الأسعار',
        imageSrc:'/images/services/menu-dev.png',
        icon:IoFastFoodOutline,
        stepsHeading:'مراحل تصميم قوائم الطعام الاكترونية في شركة ڤيلوسيتي',
        steps:[
            {
                stepName:'مرحلة الإطلاع على القائمة الحالية',
                stepDesc:'تتضمن هذه المرحلة فهم نوع الاطباق التي يقدمها المقهى و ذلك من خلال إجتماع واحد',
                stepImage:'/images/steps/meeting.png',
                stepIcon:BsStars
            },
            {
                stepName:'مرحلة التصميم',
                stepDesc:'يتم في هذه المرحلة تصميم شكل القائمة الالكترونية و القيام بالتعديلات ان وجد',
                stepImage:'/images/steps/design.png',
                stepIcon:MdOutlineDesignServices
            },
            {
                stepName:'مرحلة البرمجة',
                stepDesc:'بمجرد الانتهاء من مرحلة التصميم يتم تحويله الى القائمة النهائية مع العلم أنه لا يمكن اجراء تعديلات على التصميم في هذه المرحلة',
                stepImage:'/images/steps/developing.png',
                stepIcon:IoCodeSlash
            },
            {
                stepName:'الإشراف و الدعم',
                stepDesc:'لا تنتهي مهمتنا بمجرد التسليم بل تمتد الى الاشراف ويشمل ذلك إدارة المحتوى و تعديل الاصناف و الاسعار',
                stepImage:'/images/steps/supporting.png',
                stepIcon:FaRocket
            },
        ],
        technologies: [
            {
                technoName:'للتصميم',
                technos:[
                    '/images/technologies/figma.png',
                    '/images/technologies/photoshop.png',
                ]
            },
            {
                technoName:'للبرمجة',
                technos:[
                    '/images/technologies/html.png',
                    '/images/technologies/sass.png',
                    '/images/technologies/js.png',
                    '/images/technologies/react.png',
                    '/images/technologies/node.png',
                ]
            },
            {
                technoName:'للنشر',
                technos:[
                    '/images/technologies/goDaddy.png',
                    '/images/technologies/hostinger.png',
                    '/images/technologies/aws.png',
                ]
            },
        ]
    },
    {
        name:'برمجة بوتات الدردشة',
        desc:'اضمن وسيلة للاستجابة مع استفسارات الزباين في أسرع وقت',
        imageSrc:'/images/services/bot-dev.png',
        icon:IoChatbubblesOutline,
        stepsHeading:'مراحل برمجة بوتات الدردشة في شركة ڤيلوسيتي',
        steps:[
            {
                stepName:'مرحلة الاستكشاف',
                stepDesc:'تتضمن هذه المرحلة تحديد مزايا بوت الدردشة و المنصات التي سيعمل عليها',
                stepImage:'/images/steps/meeting.png',
                stepIcon:BsStars
            },
            {
                stepName:'مرحلة البرمجة',
                stepDesc:'بمجرد فهم الية عمل البوت نبدأ مباشرة في مرحلة البرمجة',
                stepImage:'/images/steps/developing.png',
                stepIcon:IoCodeSlash
            },
            {
                stepName:'مرحلة الاختبار',
                stepDesc:'وتعتبر هذه المرحلة النهائية و هدفها الأساسي ضمان عمل البوت بشكل متكامل و بدون أي أخطاء',
                stepImage:'/images/steps/testing.png',
                stepIcon:VscDebug
            },
            {
                stepName:'الإشراف و الدعم',
                stepDesc:'وتشمل هذه المرحلة المتابعة و اجراء التعديلات ',
                stepImage:'/images/steps/supporting.png',
                stepIcon:FaRocket
            },
        ],
        technologies: [
            {
                technoName:'للبرمجة',
                technos:[
                    '/images/technologies/js.png',
                    '/images/technologies/node.png',
                    '/images/technologies/express.png',
                    '/images/technologies/mondodb.png',
                ]
            },
            {
                technoName:'للنشر',
                technos:[
                    '/images/technologies/hostinger.png',
                    '/images/technologies/aws.png',
                    '/images/technologies/google.png',
                ]
            },
        ]
    },
]
const Projects = [
    {
        softwareDesc:'تطوير تطبيق مخصص لتنسيق المهام التسويقية لصالح شركة viral agency الرائدة في مجال التسويق ',
        softwareImage:'/images/projects/viral/project-image.png',
        companyImage:'/images/projects/viral/logo.png',
        companyName:'Viral agency',
        companyType:'شركة تسويق',
        projectColor:'#F0695C',
        companyTypeIcon:TbSpeakerphone,
        softwareType:'تطبيق',
        projectPlan: [
            {
                icon:VscChecklist,
                name:'الأهداف',
                fillTxt:[
                    'توفير نظام لتسهيل المهام اليومية',
                    'اعطاء طابع احترافي للمشروع',
                    'جعل التواصل بين العملاء و الموظفين أكثر فاعلية '
                ]
            },
            {
                icon:FaUsers,
                name:'الفريق',
                fillTxt:[
                    'محلل نظم',
                    'مصمم واجهات',
                    'مبرمج تطبيقات'
                ]
            },
            {
                icon:IoTimerOutline,
                name:'الفترة الزمنية',
                fillTxt:[
                    '10 أسابيع'
                ]
            },
            {
                icon:IoEyeOutline,
                name:'عدد الواجهات',
                fillTxt:[
                    '19 واجهة'
                ]
            },
        ],
        overView:`
            نظام  viral agency هو تطبيق ويب شامل تم تصميمه لصالح شركة التسويق (viral agency) يعتبر هذا النظام منصة متكاملة لتبسيط وتعزيز التواصل الداخلي، إدارة المهام، تتبع المشاريع والخطط التسويقية.

            النظام يسمح للموظفين بعرض وإدارة مهامهم اليومية مثل تصوير الفيديو، تصميم الجرافيك، وإنشاء المحتوى. كما يوفر لفريق الإدارة لوحة تحكم تقدم نظرة عامة عن المشاريع الجارية وإنتاجية الموظفين.

            كما يمكن للعملاء عرض آخر  التحديثات على خططهم التسويقية.

            ومن اهداف إنشاء النظام تعزيز الكفاءة التشغيلية، تحسين التواصل، و الزيادة من رضا العملاء. و تحسين العمل الجماعي وتقديم خدمات تسويقية استثنائية، مما يعزز مكانة شركة فيرال كشريك تسويقي.
        `,
        technologies: [
            {
                technoName:'UI / UX design',
                technos:[
                    '/images/technologies/figma.png'
                ]
            },
            {
                technoName:'Frontend',
                technos:[
                    '/images/technologies/js.png',
                    '/images/technologies/react-native.png',
                    '/images/technologies/redux.png',
                    '/images/technologies/expo.png',
                ]
            },
            {
                technoName:'Backend',
                technos:[
                    '/images/technologies/node.png',
                    '/images/technologies/mongodb.png',
                ]
            },
            {
                technoName:'cloud',
                technos:[
                    '/images/technologies/google-play.png',
                    '/images/technologies/app_store.png',
                    '/images/technologies/aws.png',
                ]
            },
        ]
    },
    {
        softwareDesc:'تطوير منصة تعليمية لصالح شركة space tech المختصة في مجال التداول',
        softwareImage:'/images/projects/space-tech/project-image.png',
        companyImage:'/images/projects/space-tech/logo.png',
        companyName:'Space tech',
        companyType:'شركة تداول',
        projectColor:'#5B80FF',
        companyTypeIcon:FaBtc,
        softwareType:'منصة تعليمية',
        projectPlan: [
            {
                icon:VscChecklist,
                name:'الأهداف',
                fillTxt:[
                    'توفير نظام يمكن الزبائين من الاشتراك في المحتوى التعليمي و التوصيات',
                    'أتمتة جميع المهام من الدفع و الاشتراك الى تذكير المشتركين قبل انتهاء صلاحيات اشتراكهم',
                    'اعطاء طابع احترافي للعلامة',
                ]
            },
            {
                icon:FaUsers,
                name:'الفريق',
                fillTxt:[
                    'محلل نظم',
                    'مصمم واجهات و مصمم جرافيكي',
                    'مبرمج full-stack'
                ]
            },
            {
                icon:IoTimerOutline,
                name:'الفترة الزمنية',
                fillTxt:[
                    '6 أسابيع'
                ]
            },
            {
                icon:IoEyeOutline,
                name:'عدد الواجهات',
                fillTxt:[
                    '13 واجهة'
                ]
            },
            {
                icon:LuMonitorSmartphone,
                name:'مكونات النظام',
                fillTxt:[
                    'موقع شغال على جميع الأجهزة',
                    'بوت تيليجرام',
                    'نظام الbackend ',
                    'نسختين من لوحة التحكم mac & win',
                ]
            },
        ],
        overView:`
            Space Tech Educational Platform هي منصة ويب تعليمية تم تصميمها خصيصًا لتلبية احتياجات شركة التداول في المجال التقني. توفر هذه المنصة للمستخدمين تجربة تعليمية شاملة .

            من خلال Space Tech Educational Platform، يمكن للمستخدمين  إنشاء حسابات شخصية مخصصة تتيح لهم الوصول إلى مجموعة متنوعة من الدورات التعليمية الحديثة في مجال التداول.كما يمكنهم أيضًا الاشتراك في قنوات التوصيات والحصول على أحدث الصفقات.

            و بفضل واجهة المستخدم البديهية وسهلة الاستخدام، يمكن للمستخدمين تكملة عمليات الدفع بسهولة . بالإضافة إلى ذلك، تتيح المنصة إرسال إشعارات للمستخدمين قبل انتهاء اشتراكاتهم، مما يساعدهم على التجديد و الاستمرار في المحتوى التعليمي.
        `,
        technologies: [
            {
                technoName:'UI / UX design',
                technos:[
                    '/images/technologies/figma.png',
                    '/images/technologies/photoshop.png',
                ]
            },
            {
                technoName:'Frontend',
                technos:[
                    '/images/technologies/react.png',
                    '/images/technologies/redux.png',
                    '/images/technologies/sass.png',
                    '/images/technologies/electron.png',
                ]
            },
            {
                technoName:'Backend',
                technos:[
                    '/images/technologies/js.png',
                    '/images/technologies/express.png',
                    '/images/technologies/mongodb.png',
                    '/images/technologies/bot-father.png',
                ]
            },
            {
                technoName:'cloud',
                technos:[
                    '/images/technologies/goDaddy.png',
                    '/images/technologies/hostinger.png',
                    '/images/technologies/aws.png',
                ]
            },
        ]
    },
    // {
    //     softwareDesc:'',
    //     softwareImage:'',
    //     companyImage:'',
    //     companyName:'',
    //     companyType:'',
    //     companyTypeIcon:'',
    //     softwareType:'',
    //     projectPlan: [
    //         {
    //             icon:'',
    //             name:'',
    //             fillTxt:[]
    //         }
    //     ],
    //     overView:'',
    //     technologies: [
    //         {
    //             technoName:'',
    //             technos:[
    //                 ''
    //             ]
    //         },
    //     ]
    // },
]



export {Services,Projects}