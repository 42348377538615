import React, { useState } from 'react'
import { MdEmail } from "react-icons/md";
import { sendMessage } from '../messageSender';
import { TbMessage2Check } from "react-icons/tb";



export const Contact = ({theme,activeSection}) => {
  const [formData,setFormData] = useState({name:'',phoneNumber:'',intrest:[],notes:''})
  const [isLoading,setIsLoading] = useState(false)
  const [isSent,setIsSent] = useState(false)

  const allInterests = [
    'تطبيق','موقع تعريفي','منصة تعليمية','بوت دردشة','متجر الكتروني','حل برمجي','قائمة طعام','نظام ارشفة','أخرى'
  ]

  const handleChange = (e) => {
    const { name , value } = e.target
    setFormData(prev => ({...prev,[name]:value}))
  }

  const doesInterestExists = (e) => {
    return formData.intrest.includes(e)
  }

  const handleChangeCheckBox = (e) => {
    setFormData(prev => {
      if(doesInterestExists(e)){
        const newinterest = prev.intrest.filter(ele => ele !== e)
        return {...prev,intrest:newinterest}
      }else {
        const newinterest = [...prev.intrest,e]
        return {...prev,intrest:newinterest}
      }
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const { name,phoneNumber,intrest,notes } = formData
    if(name === '' || phoneNumber.length < 8 || intrest.length < 1){

    }else {
      sendMessage(name,phoneNumber,intrest,notes,setIsLoading,setIsSent)
    }
  }
  return (
    <div className={`contact-component container ${theme} ${activeSection.includes('section5') && 'active-section'}`} id="section5">
      <h2 className='heading-text TXT-heading'>مشروعك الجاي معانا!</h2>
      <p className='desc-text TXT-normal'>ابعتلنا شوية تفاصيل و خلي الباقي علينا</p>
      <article className='holder'>
        <form className='main-form' action="">
          {isSent &&
            <div className='message-sent-div'>
              <span className='icon'>{TbMessage2Check({})}</span>
              <h2 className='message-sent-txt TXT-heading2'>
                تم استلام رسالتك بنجاح (سيتم الرد خلال 12 ساعة كحد أقصى)!
              </h2>
            </div>
          }
          <div className='inputs-holder'>
            <div className='input-holder'>
              <label htmlFor="name" className='TXT-heading3'>الاسم*</label>
              <input 
                id='name' 
                placeholder='الاسم الثلاثي' 
                type="text"
                name='name'
                onChange={handleChange}
                className='TXT-normal'
              />
            </div>
            <div className='input-holder'>
              <label htmlFor="phoneNumber" className='TXT-heading3'>رقم الهاتف*</label>
              <input 
                id='phoneNumber' 
                placeholder='09X-XXXXXXX' 
                type="text"
                name='phoneNumber'
                onChange={handleChange}
                className='TXT-normal'
              />
            </div>
          </div>
          <h2 className='interested-heading TXT-heading3'>نوع المشروع المهتم بيه</h2>
          <div className='project-types'>
            {allInterests.map((e,i)=><div className='check-input-holder' key={i} onClick={() => handleChangeCheckBox(e)}>
              <input 
                type='checkbox'
                checked={doesInterestExists(e)}
              />
              <label className='TXT-normal'>{e}</label>
            </div>)
            }
          </div>
          <h2 className='note-heading TXT-heading3'>لأي معلومات اضافية</h2>
          <textarea 
            className='textarea-input TXT-normal' 
            placeholder='ملاحظات' 
            type="text"
            name='notes'
            onChange={handleChange}
          ></textarea>
          <button className='submit-btn TXT-heading3' onClick={handleSubmit}>
            {isLoading?
              <span className='loading-span'></span>
            :
            'إرسال' 
            }
          </button>
        </form>
        <ul className='contact-details' role='list'>
          <li className='info'>
            <img src="/images/velocity-logo.png" alt="" />
            <h2 className='name TXT-heading2'>Malik Kajiji</h2>
            <p className='role TXT-normal'>Founder & CEO</p>
          </li>
          <li className='email'>
            <p className='TXT-heading3'>{process.env.REACT_APP_EMAIL}</p>
            <span className='icon TXT-heading'>
              {MdEmail({})}
            </span>
          </li>
          <li className='phone'>
            <p className='TXT-heading3' dir='ltr'>{process.env.REACT_APP_PHONE_NUMBER}</p>
            <img src="/images/Flag-Libya-sq.png" alt="" />
          </li>
        </ul>
      </article>
    </div>
  )
}
