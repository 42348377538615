import { useEffect } from "react";
import { sendMessage } from "./messageSender";
import { Header,Footer } from "./components";
import './styles/global.scss'
import {HashRouter,Route , Routes, useLocation } from 'react-router-dom';
import { Contact, Home, Project, Projects, Service , About } from './pages'

function App() {
  return (
    <HashRouter basename="/">
      <main className="App" dir="rtl">
        <Header />
        <Routes >
          <Route  path='/' element={<Home />} />
          <Route path='/our-work' element={<Projects />} />
          <Route path={`/service/:index`} element={<Service />} />
          <Route path={`/contact`} element={<Contact />} />
          <Route path={`/projects/:index`} element={<Project />} />
          <Route path={`/about-us`} element={<About />} />
        </Routes>
        <Footer />
      </main>
    </HashRouter>
  );
}

export default App;
