import React from 'react'
import { RiFacebookBoxFill,RiInstagramFill  } from "react-icons/ri";


export const Footer = () => {
  return (
    <footer className='main-footer' dir='ltr'>
      <div className='container'>
        <div className='holder'>
          <img className='vc-logo' src="/images/velocity-logo2.png" alt="" />
          <p className='TXT-normal vc-desc'>
            Velocity is a leading technology company in the field of developing websites, applications and designing software solutions for all types of companies
          </p>
          <p className='contact-txt TXT-normal'>
            Contact
          </p>
          <div className='phone-number'>
            <img className='flag' src="/images/Flag-Libya.png" alt="" />
            <p className='TXT-normal number'>{process.env.REACT_APP_PHONE_NUMBER}</p>
          </div>
          <p className='follow-txt TXT-normal'>
            Follow us
          </p>
          <div className='details'>
            <p className='icons'>
              <a href="https://www.facebook.com/profile.php?id=61562271604404" target='_blank'>
                <span className='TXT-heading'>{RiFacebookBoxFill({})}</span>
              </a>
              <a href="https://www.instagram.com/velocity.software.development/" target='_blank'>
                <span className='TXT-heading'>{RiInstagramFill({})}</span>
              </a>
            </p>
            <p className='email TXT-normal'>
              {process.env.REACT_APP_EMAIL}
            </p>
          </div>
          <p className='rights TXT-footer'>
            Copyright © 2024. Velocity. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}
