const sendMessage = (name,phoneNumber,arr,notes,setIsLoading,setIsSent) => {
    const TELEGRAM_API_BASE_URL = 'https://api.telegram.org/bot';
    const BOT_TOKEN = process.env.REACT_APP_BOT_TOKEN;
    const OWNER_CHAT_ID = process.env.REACT_APP_OWNER_CHAT_ID;

    setIsLoading(true)

    let allService = ''
    arr.forEach(e => allService += `${e} - `);
    const message = `
        ${name}
        ------------------------
        ${phoneNumber}
        ------------------------
        ${allService}
        ------------------------
        ${notes}
    `
    
    const messageData = {
        chat_id: OWNER_CHAT_ID,
        message,
        text: message
    };

    fetch(`${TELEGRAM_API_BASE_URL}${BOT_TOKEN}/sendMessage`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(messageData),
    })
    .then(response => response.json())
    .then(data => setIsSent(true))
    .catch(error => setIsLoading(false));
};

export {sendMessage}