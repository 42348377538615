import React, { useState } from 'react'
import '../styles/about.scss'
import { FaArrowLeftLong,FaMapLocationDot } from 'react-icons/fa6'
import { Link } from 'react-router-dom'
import { FaUsers, FaHands } from "react-icons/fa";
import { GiProcessor } from "react-icons/gi";
import { BsStars } from "react-icons/bs";
import { GoChecklist } from "react-icons/go";
import { IoHandLeftOutline } from "react-icons/io5";
import { RiSpeedUpLine } from "react-icons/ri";
import { MdOutlineMyLocation } from "react-icons/md";



const About = () => {
    const benefits = [
        {icon:FaUsers,name:'فريق مرن',desc:'يتكيف فريق العمل مع تغير متطلبات المشروع'},
        {icon:GiProcessor,name:'حلول فعالة',desc:'ضمان فاعلية و انتاجية النظام من أولوياتنا عند تنفيذ أي مشروع'},
        {icon:BsStars,name:'الشفافية',desc:'توضيح جميع المتطلبات و الصورة النهائية للمشروع من قبل البدء في العمل'},
        {icon:GoChecklist,name:'الإلتزام بالمعايير',desc:'ضمان جودة أي مشروع يتم إنشاءه سواء من ناحية الفاعلية أو العملية'},
        {icon:IoHandLeftOutline,name:'الاستلام التام',desc:'استلام المشروع بشكل تام من العروض المبدئية و الاقتراحات الى مرحلة نشر المشروع و التتبع'},
        {icon:RiSpeedUpLine,name:'السرعة',desc:'يتميز فريق العمل بالسرعة في فهم المشروع و سرعة التنفيذ بدون التقليل في جودة المشروع'},
    ]
    const [choosenTab,setChoosenTab] = useState(0)


    return (
        <section className='about-page'>
            <div className='landing-image'>
                <div className='container'>
                    <aside className='text'>
                        <h2 className='heading-txt'>ڤيلوسيتي للخدمات التقنية</h2>
                        <p className='desc-txt TXT-heading3'>
                        هدفنا مساعد الشركات و المشاريع على إطلاق العنان لإمكانياتهم الكاملة عن طريق الحلول البرمجية و التقنية.
                        </p>
                        <Link to={'/contact'}>
                            <button className='contact-btn TXT-heading3'> 
                                للتواصل 
                                <span className='icon TXT-heading2'>
                                    {FaArrowLeftLong({})}
                                </span>
                            </button>
                        </Link>
                    </aside>
                </div>
            </div>
            <div className='container'>
                <h2 className='TXT-heading libya-heading'>
                    نشتغل في جميع الأراضي اللليبية
                </h2>
                <div className='libya'>
                    <img className='libya-map' src="/images/libya-map.png" alt="" />
                    <div className='phone-number'>
                        <img className='flag' src="/images/Flag-Libya.png" alt="" />
                        <p className='number TXT-normal' dir='ltr'>{process.env.REACT_APP_PHONE_NUMBER}</p>
                    </div>
                </div>
                <h2 className='TXT-heading benefits-heading'>
                    شن اللي يميزنا عن غيرنا
                </h2>
                <ul className='benefits' role='list'>
                    {benefits.map((e,i)=><li>
                        <span className='icon'>
                            {e.icon({})}
                        </span>
                        <h2 className='TXT-heading2'>
                            {e.name}   
                        </h2>
                        <p className='TXT-normal'>
                            {e.desc}
                        </p>
                    </li>)}
                </ul>
                <ul className='tabs' role='list'>
                    <li className={`tab ${choosenTab === 0 && 'choosen'}`} onMouseEnter={()=>setChoosenTab(0)}>
                        <span className='icon'>
                            {MdOutlineMyLocation({})}
                        </span>
                        <p className='text TXT-heading3'>
                            رؤية الشركة 
                        </p>
                    </li>
                    <li className={`tab ${choosenTab === 1 && 'choosen'}`} onMouseEnter={()=>setChoosenTab(1)}>
                        <span className='icon'>
                            {FaMapLocationDot({})}
                        </span>
                        <p className='text TXT-heading3'>
                            الهدف المحلي    
                        </p>
                    </li>
                    <li className={`tab ${choosenTab === 2 && 'choosen'}`} onMouseEnter={()=>setChoosenTab(2)}>
                        <span className='icon'>
                            {FaHands({})}
                        </span>
                        <p className='text TXT-heading3'>
                            المباديء
                        </p>
                    </li>
                </ul>
                {choosenTab === 0 && <article className='tabs-text'>
                    <aside className='aside-text'>
                        <h2 className='TXT-heading3'>
                            النجاح المشترك
                        </h2>
                        <p className='TXT-normal'>
                            نسعى إلى تحقيق العديد من النجاحات المشتركة مع عملائنا عن طريق ضمان جودة أنظمتنا
                        </p>
                        <h2 className='TXT-heading3'>
                            تحويل الأفكار الى واقع
                        </h2>
                        <p className='TXT-normal'>
                            مهما كانت أفكار عملاؤنا مميزة و فريدة فنهدف دائما لإيجاد أفضل الحلول و استعمال أحدث التقنيات
                        </p>
                    </aside>
                    <img className='tab-image' src="/images/vs-2.png" alt="" />
                </article>}
                {choosenTab === 1 && <article className='tabs-text'>
                    <aside className='aside-text'>
                        <h2 className='TXT-heading3'>
                            الوعي الالكتروني
                        </h2>
                        <p className='TXT-normal'>
                            تغيير نظرة المجتمع حول الثقافة الالكترونية و الاعتياد على المعاملات الالكترونية
                        </p>
                        <h2 className='TXT-heading3'>
                            زيادة التواجد الالكتروني
                        </h2>
                        <p className='TXT-normal'>
                            توضيح أهمية إنشاء المواقع الالكترونية و التطبيقات لجميع أحجام و أنواع المشاريع
                        </p>
                    </aside>
                    <img className='tab-image' src="/images/vs-1.png" alt="" />
                </article>}
                {choosenTab === 2 && <article className='tabs-text'>
                    <aside className='aside-text'>
                        <h2 className='TXT-heading3'>
                            فاعلية الأنظمة
                        </h2>
                        <p className='TXT-normal'>
                            نهدف دائما الى انشاء حلول برمجية متكافئة للمشاكل المراد حلها
                        </p>
                        <h2 className='TXT-heading3'>
                            الوصول لأعلى مستوى نجاح
                        </h2>
                        <p className='TXT-normal'>
                            يتم استلام جميع مشاريعنا بشكل تام من مرحلة العروض المبدئية الى النشر و المتابعة لضمان انشاء أنظمة مميزة و فعالة
                        </p>
                    </aside>
                    <img className='tab-image' src="/images/vs-3.png" alt="" />
                </article>}
                <div className='bottom'>
                    <p className='TXT-heading3'>
                        مزال مش متأكد؟ 
                        <span className='TXT-heading2'>
                            تعرف علينا أكثر.
                        </span>
                    </p>
                    <Link to={'/our-work'}>
                        <button className='TXT-heading3 contact-btn'>
                            عرض أعمالنا 
                        </button>
                    </Link>
                </div>
            </div>
        </section>
    )
}

export default About