import React from 'react'
import '../styles/projects.scss'
import { GoArrowUpLeft } from 'react-icons/go'
import { Link } from 'react-router-dom'
import { Projects as AllProjects } from '../Data'
import { Contact } from '../components'

const Projects = () => {
    return (
        <section className='projects'>
            <article className={`our-work container`}>
                <h2 className='our-work-heading TXT-heading'>
                    <span style={{'--d':'0.0s'}}>
                        من 
                    </span > <span style={{'--d':'0.3s'}}>
                        أعمالنا 
                    </span> <span style={{'--d':'0.6s'}}>
                        السابقة
                    </span>
                </h2>
                <ul className='our-work-list' role='list'>
                    {AllProjects.map((e,i)=><Link to={`/projects/${i}`} className='card' key={i}>
                        <li style={{'--d':`${(i*0.4)}s`}}>
                            <div className='projects-details'>
                                <p className='company' style={{'--cl':`${e.projectColor}`}}>
                                    <span className='icon TXT-heading2'>
                                        {e.companyTypeIcon({})}
                                    </span>
                                    <span className='text TXT-normal'>
                                        {e.companyType}
                                    </span>
                                </p>
                                <p className='software TXT-normal'>
                                    {e.softwareType}
                                </p>
                            </div>
                            <h2 className='software-heading TXT-heading2'>
                                {e.softwareDesc}
                            </h2>
                            <img className='project-image' src={e.softwareImage} alt="" />
                            <span className='learn-more TXT-normal'>
                                أعرف أكثر
                                <span className='icon TXT-heading'>
                                    {GoArrowUpLeft({})}
                                </span>
                            </span>
                        </li>
                    </Link>)

                    }
                </ul>
            </article>
            <Contact theme={'light'} activeSection={[]}/>
        </section>
    )
}

export default Projects