import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { RiArrowDropDownLine,RiArrowLeftUpLine } from "react-icons/ri";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { IoIosClose } from "react-icons/io";
import { FiMonitor } from "react-icons/fi";
import { Services } from '../Data';


export const Header = () => {
  const [isListShown,setIsListShown] = useState(false)
  const [isServiceShowen,setIsServiceShowen] = useState(false)
  const [isServiceListShowen,setIsServiceListShowen] = useState(false)
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const pathname = useLocation().pathname

  const serviceRef = useRef(null);
  const listRef = useRef(null);

  useEffect(()=>{
    const handleMouseEnter = () => {
      setIsServiceListShowen(true);
    };

    const handleMouseLeave = () => {
      setIsServiceListShowen(false);
    };

    if (serviceRef.current) {
      serviceRef.current.addEventListener('mouseenter',handleMouseEnter);
      serviceRef.current.addEventListener('mouseleave', handleMouseLeave);
    }
    if (listRef.current) {
      listRef.current.addEventListener('mouseenter', handleMouseEnter);
      listRef.current.addEventListener('mouseleave', handleMouseLeave);
    }
  },[])
  useEffect(()=>{
    setIsListShown(false)
    window.scrollTo(0, 0);
  },[pathname])
  return (
    <header className='main-header'>
      <div className='container'>
        <nav className='holder'>
          <button className='tab-btn' onClick={()=>setIsListShown(true)}>
            {HiOutlineMenuAlt3({})}
          </button>
          <Link to={'/contact'} className=''>
            <button className='contact-btn TXT-heading3'>
              تواصل الآن!
            </button>
          </Link>
          <ul role='list' className='tabs TXT-heading2'>
            <li>
              <Link to={'/our-work'}>
                أعمالنا
              </Link>
            </li>
            <li className='service-tap' ref={serviceRef}>
              <p>
                خدماتنا 
              </p>
              <span className='show-icon'>
                {RiArrowDropDownLine({})}
              </span>
            </li>
            {/* <li>
              <Link to={'/about-us'}>
                من نحن 
              </Link>
            </li> */}
            <li>
              <Link to={'/about-us'}>
                من نحن 
              </Link>
            </li>
          </ul>
          <Link to={'/'}>
            <img src="/images/velocity-logo3.png" alt="" className='logo' />
          </Link>
        </nav>
      </div>
        <article className={`services-list ${isServiceListShowen && 'show-services-list'}`} ref={listRef} onClick={()=>setIsServiceListShowen(false)}>
          <div className='service-image'>
            {Services.map((e,i)=><img key={i} src={e.imageSrc} className={i === hoveredIndex && 'active'} alt="" />)}
          </div>
          <ul className='list' role='list'>
            <p className='list-heading TXT-normal'>الخدمات المتوفرة</p>
            {Services.map((e,i)=><Link to={`/service/${i}`} key={i} onMouseEnter={()=>setHoveredIndex(i)}>
              <li>
                <span className='icon'>{e.icon({})}</span>
                <h2 className='name TXT-heading3'>{e.name}</h2>
                <p className='description TXT-footer'>{e.desc}</p>
              </li>
            </Link>)
            }
          </ul>
        </article>
      <div className={`phone-header ${isListShown && 'showen'}`}>
        <div className='top'>
          <div className='container'>
            <nav className='holder'>
              <span className='close-icon' onClick={()=>setIsListShown(false)}>{IoIosClose({})}</span>
              <img src="/images/velocity-logo3.png" alt="" className='logo' />
            </nav>
          </div>
        </div>
        <ul className='mid TXT-heading2'>
          <li>
            <Link className='link container' to={'/our-work'}>
              <p className=''>
                <span>
                  أعمالنا
                </span>
                <span className='icon'>
                  {RiArrowLeftUpLine({})}
                </span>
              </p>
            </Link>
          </li>
          <li className={`${isServiceShowen && 'show-service'}`} onClick={()=>setIsServiceShowen(prev => !prev)}>
            <div className={`link container`}>
              <p className=''>
                <span>
                  خدماتنا 
                </span>
                <span className='icon'>
                  {RiArrowDropDownLine({})}
                </span>
              </p>
              <ul role='list' className='service-list'>
                {Services.map((e,i)=><Link className='service' to={`/service/${i}`} key={i}>
                  <span className='icon'>
                    {e.icon({})}
                  </span>
                  <p className='TXT-heading3'>
                    {e.name}
                  </p>
                </Link>)
                }
              </ul>
            </div>
          </li>
          <li>
            <Link className='link container' to={'/about-us'}>
              <p className=''>
                <span>
                  من نحن
                </span>
                <span className='icon'>
                  {RiArrowLeftUpLine({})}
                </span>
              </p>
            </Link>
          </li>
        </ul>
        <div className='contact-us-btn'>
          <div className='container'>
            <Link to={'/contact'} className='btn TXT-heading3'>
              تواصل الآن!
            </Link>
          </div>
        </div>
      </div>
    </header>
  )
}
