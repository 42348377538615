import React, { useEffect, useState } from 'react'
import { FaArrowLeftLong } from "react-icons/fa6";
import '../styles/home.scss'
import { Link, useLocation } from 'react-router-dom';
import { GoArrowUpLeft } from "react-icons/go";
import { Services,Projects } from '../Data';
import { Contact } from '../components';



const Home = () => {
    const words = [
        {word:'لدقة',l:140,m:120,s:100}, 
        {word:'لكفاءة',l:190,m:160,s:130}, 
        {word:'لسرعة',l:200,m:170,s:140}, 
    ];
    const [currentWord,setCurrentWord] = useState({word:'لدقة',l:140,m:120,s:100});
    const [isShowen,setIsShowen] = useState(true);

    useEffect(()=>{
        let index = 1
        setInterval(()=>{
            if(index === words.length) index = 0
            setIsShowen(false)
            setTimeout(()=>{
                setCurrentWord(words[index])
                setIsShowen(true)
                index += 1
            },300)
        },4200)
        setIsShowen(true)
    },[])


    //ative section stuff
    const {pathname} = useLocation()
    const [activeSection, setActiveSection] = useState([]);

    const [sectionHeights,setSectionsHeights] = useState({
        section1:0,
        section2:0,
        section3:0,
        section4:0,
        section5:0
    })

    useEffect(() => {
        //to get heights
        const handleScroll = () => {
        const scrollPosition = window.pageYOffset;
    
        if(scrollPosition >= 0 && scrollPosition <= sectionHeights.section2){
        setActiveSection(prev => prev.length === 0 ? ['section1']:prev)
        }else if(scrollPosition > sectionHeights.section2 && scrollPosition <= sectionHeights.section3){
        setActiveSection(prev => prev.length === 1 ? ['section1','section2']:prev)
        }else if(scrollPosition > sectionHeights.section3 && scrollPosition <= sectionHeights.section4){
        setActiveSection(prev => prev.length === 2 ? ['section1','section2','section3']:prev)
        }else if(scrollPosition > sectionHeights.section4 && scrollPosition <= sectionHeights.section5) {
        setActiveSection(prev => prev.length === 3 ? ['section1','section2','section3','section4']:prev)
        }else {
        setActiveSection(prev => prev.length === 4 ? ['section1','section2','section3','section4','section5']:prev)
        }
        };
        //to get heights
        handleResize()
        handleScroll()
        // Add event listener to handle scroll
        window.addEventListener('scroll', handleScroll);
    
        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [sectionHeights.section2]);

    useEffect(()=>{
        setTimeout(()=>{
            const scrollPosition = window.pageYOffset;
        
            const s1 = document.getElementById('section1').offsetTop - (document.getElementById('section1').offsetTop * 1);
            const s2 = document.getElementById('section2').offsetTop - (document.getElementById('section2').offsetTop * 0.5);
            const s3 = document.getElementById('section3').offsetTop - (document.getElementById('section3').offsetTop * 0.2);
            const s4 = document.getElementById('section4').offsetTop - (document.getElementById('section4').offsetTop * 0.2);
            const s5 = document.getElementById('section5').offsetTop - (document.getElementById('section5').offsetTop * 0.2);
        
            if(scrollPosition >= 0 && scrollPosition <= s2){
                setActiveSection(['section1'])
            }else if(scrollPosition > s2 && scrollPosition <= s3){
                setActiveSection(['section1','section2'])
            }else if(scrollPosition > s3 && scrollPosition <= s4){
                setActiveSection(['section1','section2','section3'])
            }else if(scrollPosition > s4 && scrollPosition <= s5) {
                setActiveSection(['section1','section2','section3','section4'])
            }else {
                setActiveSection(['section1','section2','section3','section4','section5'])
            }

        },600)
    },[pathname])
    
    const handleResize = () => {
        const s1 = document.getElementById('section1').offsetTop - (document.getElementById('section1').offsetTop * 0.3);
        const s2 = document.getElementById('section2').offsetTop - (document.getElementById('section2').offsetTop * 0.5);
        const s3 = document.getElementById('section3').offsetTop - (document.getElementById('section3').offsetTop * 0.2);
        const s4 = document.getElementById('section4').offsetTop - (document.getElementById('section4').offsetTop * 0.2);
        const s5 = document.getElementById('section5').offsetTop - (document.getElementById('section5').offsetTop * 0.2);
    
        setSectionsHeights({section1:s1,section2:s2,section3:s3,section4:s4})
        setSectionsHeights({section1:s1,section2:s2,section3:s3,section4:s4,section5:s5})
    }
    
    return (
        <section className='home'>
            <article className={`landing ${activeSection.includes('section1') && 'active-section'}`} id="section1">
                <div className='container'>
                    <div className='holder'>
                        <aside className='text'>
                            <h2 className='heading-txt'>تتميز أعمالنا با</h2>
                            <h1 className={`changing-word`} >
                                <p className={`${isShowen && 'showen'}`} style={{'--l':currentWord.l,'--m':currentWord.m,'--s':currentWord.s}}>{currentWord.word}</p>
                            </h1>
                            <p className='desc-txt TXT-normal'>
                                ڤيلوسيتي هي شركة تقنية رائدة في مجال تطوير المواقع و التطبيقات و تصميم الحلول البرمجية لكافة أنواع الشركات 
                            </p>
                            <a href="#section5">
                                <button className='contact-btn TXT-heading3'> 
                                    للتواصل 
                                    <span className='icon TXT-heading2'>
                                        {FaArrowLeftLong({})}
                                    </span>
                                </button>
                            </a>
                        </aside>
                        <img className='landing-image' src="/images/landing-image.png" alt="" />
                    </div>
                </div>
            </article>
            <article className={`clients ${activeSection.includes('section2') && 'active-section'}`} id="section2">
                <h2 className='TXT-heading2'>من عملاؤنا المميزين</h2>
                <img className='clients-image' src="/images/clients.png" alt="" />
            </article>
            <article className={`serivces container ${activeSection.includes('section3') && 'active-section'}`} id="section3">
                <h2 className='services-heading TXT-heading'>
                    <span style={{'--d':'0.0s'}}>
                        الخدامات 
                    </span> <span style={{'--d':'0.3s'}}>
                        المتوفرة    
                    </span>
                </h2>
                <ul className='services-list' role='list'>
                    {Services.map((e,i)=><Link to={`/service/${i}`} className='card' key={i}>
                        <li style={{'--d':`${(i*0.2) - 0.2}s`}}>
                            <img className='card-image' src={e.imageSrc} alt="" />
                            <h2 className='TXT-heading3 card-heading'>{e.name}</h2>
                            <p className='TXT-normal desc'>{e.desc}</p>
                            <span className='learn-more TXT-normal'>
                                أعرف أكثر
                                <span className='icon TXT-heading'>
                                    {GoArrowUpLeft({})}
                                </span>
                            </span>
                        </li>
                    </Link>)
                    }
                </ul>
            </article>
            <article className={`our-work container ${activeSection.includes('section4') && 'active-section'}`} id="section4">
                <h2 className='our-work-heading TXT-heading'>
                    <span style={{'--d':'0.0s'}}>
                        من 
                    </span > <span style={{'--d':'0.3s'}}>
                        أعمالنا 
                    </span> <span style={{'--d':'0.6s'}}>
                        السابقة
                    </span>
                </h2>
                <ul className='our-work-list' role='list'>
                    {Projects.map((e,i)=><Link to={`/projects/${i}`} className='card' key={i}>
                        <li style={{'--d':`${(i*0.4)}s`}}>
                            <div className='projects-details'>
                                <p className='company' style={{'--cl':`${e.projectColor}`}}>
                                    <span className='icon TXT-heading2'>
                                        {e.companyTypeIcon({})}
                                    </span>
                                    <span className='text TXT-normal'>
                                        {e.companyType}
                                    </span>
                                </p>
                                <p className='software TXT-normal'>
                                    {e.softwareType}
                                </p>
                            </div>
                            <h2 className='software-heading TXT-heading2'>
                                {e.softwareDesc}
                            </h2>
                            <img className='project-image' src={e.softwareImage} alt="" />
                            <span className='learn-more TXT-normal'>
                                أعرف أكثر
                                <span className='icon TXT-heading'>
                                    {GoArrowUpLeft({})}
                                </span>
                            </span>
                        </li>
                    </Link>)
                    }
                </ul>
            </article>
            <Contact theme={'light'} activeSection={activeSection}/>
        </section>
    )
}

export default Home