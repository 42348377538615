import React, { useState } from 'react'
import '../styles/service.scss'
import { useParams } from 'react-router-dom'
import { Services } from '../Data'
import { FaArrowLeftLong } from 'react-icons/fa6'
import { IoIosArrowDown } from "react-icons/io";
import { Contact } from '../components'


const Service = () => {
    const { index } = useParams()
    const { name,desc,imageSrc,stepsHeading,steps,technologies } = Services[index]
    const [choosen,setChoosen] = useState(null)

    const handleChoose = (index) => {
        setChoosen(prev => {
            if(prev === index){
                return null;
            }else {
                return index;
            }
        })
    }

    return (
        <section className='service'>
            <div className="container">
                <article className='landing-article'>
                    <article className='text'>
                        <h2 className='heading-txt TXT-heading'>
                            {name}
                        </h2>
                        <p className='desc TXT-normal'>
                            {desc}
                        </p>
                        <a href="#section5">
                            <button className='contact-btn TXT-heading3'> 
                                للتواصل 
                                <span className='icon TXT-heading2'>
                                    {FaArrowLeftLong({})}
                                </span>
                            </button>
                        </a>
                    </article>
                    <img className='service-image' src={imageSrc} alt="" />
                </article>
                <h2 className='steps-heading TXT-heading'>{stepsHeading}</h2>
                <article className='steps-holder'>
                    <ul className='steps' role='list'>
                        {steps.map((e,i)=><li className={`step ${choosen === i && 'chosen'}`} key={i} onClick={()=>handleChoose(i)}>
                            <div className='li-top'>
                                <span className='icon TXT-heading'>
                                    {e.stepIcon({})}
                                </span>
                                <h2 className='step-name TXT-heading3'>
                                    {e.stepName}
                                </h2>
                                <span className='arrow-icon TXT-heading2'>
                                    {IoIosArrowDown({})}
                                </span>
                            </div>
                            <p className='step-desc TXT-normal'>
                                {e.stepDesc}
                            </p>
                            {choosen !== null && <img className='step-image-phone' src={steps[choosen].stepImage} alt="" />}
                        </li>)
                        }
                    </ul>
                    {choosen !== null && <img className='step-image' src={steps[choosen].stepImage} alt="" />}
                </article>
                <h2 className='steps-heading TXT-heading'>من التقنيات المستعملة</h2>
                <ul className='used-techno-list' role='list'>
                    {technologies.map((e,i)=><li className='technologies' key={i}>
                        <h2 className='techno-type TXT-heading2'>
                            {e.technoName}
                        </h2>
                        {e.technos.map((e,i)=><img key={i} className='techno' src={e} alt="" />)}
                    </li>)
                    }
                </ul>
            </div>
            <Contact theme={'light'} activeSection={[]} />
        </section>
    )
}

export default Service